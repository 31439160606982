import { css, StyleSheet } from 'aphrodite'

const HeaderButton = ({ clickHandler, active, children }) => {
  return (
    <button
      onClick={clickHandler}
      className={css(styles.btn, active && styles.activebtn)}
    >
      {children}
    </button>
  )
}

export default HeaderButton

const styles = StyleSheet.create({
  btn: {
    background: 'none',
    border: 0,
    fontWeight: 600,
    padding: '1rem',
    marginRight: 8,
    marginBottom: 4,
    transition: 'box-shadow .3s',
    ':hover': {
      cursor: 'pointer',
      boxShadow: '0px 5px 0 rgba(221, 29, 33, 1)',
    },
    ':focus': {
      outline: 'none',
      boxShadow: '0px 5px 0 rgba(221, 29, 33, 1)',
    },
  },
  activebtn: {
    boxShadow: '0px 5px 0 rgba(221, 29, 33, 1)',
  },
})
