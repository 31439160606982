import { css, StyleSheet } from 'aphrodite'
import axios from 'axios'
import { useState } from 'react'
import { BASE_URL } from '../../constants'
import { testUsername } from '../../helpers/testUsername'
import { useData, useStatusMessage } from '../../store'
import BreadCrumb from '../../uiElements/BreadCrumb'
import Submit from '../../uiElements/Submit'
import TextInput from '../../uiElements/TextInput'

const NewSuperAdmin = () => {
  const { setActiveSuperAdminSubPage } = useData()

  const INITIAL_NEW_SUPER_ADMIN_DATA = {
    userName: '',
    email: '',
  }
  const { setMessage } = useStatusMessage()
  const [superAdminDetails, setSuperAdminDetails] = useState(
    INITIAL_NEW_SUPER_ADMIN_DATA
  )

  const submitHandler = async (e) => {
    e.preventDefault()
    const { userName, email } = superAdminDetails
    // console.log('userName, email', userName, email)
    try {
      const clientID = JSON.parse(localStorage.getItem('userDetails')).user
        .clientID
      // console.log('clientID', clientID)
      const newSuperAdmin = {
        clientID,
        userName,
        roles: 'superAdmin',
        email,
      }
      const res = await axios.post(
        `${BASE_URL}/auth/user/register`,
        newSuperAdmin
      )
      console.log('res', res)
      if (res.statusText === 'Created') setMessage('Super Admin created...')
      setActiveSuperAdminSubPage('main')
      // console.log('res', res)
    } catch (err) {
      console.log(err)
      setMessage('Create failed:' + err.message)
    }
  }

  return (
    <div className={css(styles.container)}>
      <BreadCrumb
        items={[
          {
            name: 'SUPER ADMINS',
            clickHandler: () => setActiveSuperAdminSubPage('main'),
          },
          { name: 'NEW SUPER ADMIN' },
        ]}
      />

      <h3>CREATE NEW SUPER ADMIN</h3>
      <div className={css(styles.space)}></div>
      <form className={css(styles.form)} onSubmit={submitHandler}>
        <TextInput
          readOnly={false}
          labelText="username"
          customInputStyles={styles.fullWidth}
          value={superAdminDetails.userName}
          onChange={(e) =>
            setSuperAdminDetails((state) => ({
              ...state,
              userName: e.target.value,
            }))
          }
          inputName="userName"
          userTips
          pattern="(.){5,20}"
          id="superadmin_name"
        />

        <label className={css(styles.label)}>
          <p>email</p>
        </label>
        <input
          onFocus={() => console.log('focus')}
          type="email"
          name="email"
          value={superAdminDetails.email}
          onChange={(e) =>
            setSuperAdminDetails((state) => ({
              ...state,
              email: e.target.value,
            }))
          }
          className={css(styles.bar, styles.fullWidth)}
        />

        <div className={css(styles.space)}>
          <Submit
            value="submit"
            customStyles={styles.fullWidth}
            disabled={!testUsername(superAdminDetails.userName)}
          />
        </div>
      </form>
    </div>
  )
}

export default NewSuperAdmin

const styles = StyleSheet.create({
  form: {
    width: '20%',
  },
  label: {
    fontWeight: 600,
  },
  space: {
    marginTop: '1.5rem',
  },
  fullWidth: {
    width: '100%',
    boxSizing: 'border-box',
  },
  bar: {
    border: 0,
    outline: 0,
    background: 'rgba(217, 217, 217, 1)',
    padding: '.5rem',
    borderRadius: '.5rem',
  },
})
