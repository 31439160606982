import { css, StyleSheet } from 'aphrodite'
import Button from './Button'
import { Description } from './Description'
import SearchBar from './SearchBar'
import SortButton from './SortButton'

const ListHeader = ({
	title,
	btnContent,
	clickHandler,
	description,
	sort,
	search,
}) => {
	return (
		<div style={{ borderBottom: '2px solid rgba(219, 225, 232, 1)' }}>
			<header className={css(styles.header)}>
				<h1 className={css(styles.title)}>{title} </h1>
				<div className={css(styles.headerRightPane)}>
					{sort && <SortButton />}
					{search && (
						<SearchBar customStyles={styles.searchBarStyles} />
					)}
					{btnContent && (
						<Button clickHandler={clickHandler}>
							{btnContent}{' '}
						</Button>
					)}
				</div>
			</header>
			<p style={{ fontSize: 14, marginLeft: 40, width: '70%' }}>
				{' '}
				{description}
			</p>
		</div>
	)
}

export default ListHeader

const styles = StyleSheet.create({
	header: {
		display: 'flex',
		padding: '0 2rem 0 3rem',
		justifyContent: 'space-between',
		alignItems: 'center',
		// borderBottom: '2px solid rgba(219, 225, 232, 1)',
	},
	title: {
		fontSize: '2rem',
	},
	headerRightPane: {
		display: 'flex',
		gap: '.7rem',
	},
	searchBarStyles: {
		width: '18vw',
	},
})
