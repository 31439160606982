import { css, StyleSheet } from 'aphrodite'
import { useEffect, useRef, useState } from 'react'
import uuid from 'react-uuid'
import DeleteButton from '../uiElements/DeleteButton'
import BreadCrumb from '../uiElements/BreadCrumb'
import Button from '../uiElements/Button'
import TextInput from '../uiElements/TextInput'
import axios from 'axios'
import { BASE_URL } from '../constants'
import { useClientInfo, userUserInfo } from '../auth/hooks/useUserInfo'
import { useStatusMessage } from '../store'
import { DropDown } from '../uiElements/DropDown'
import {
	useGetAllModules,
	useModuleAssignments,
} from '../auth/hooks/moduleApiHooks'

const newContent = { module: '', name: '', content: '', lastEdited: '' }
const ContentManagement = () => {
	const [list, setList] = useState([])
	const user = userUserInfo()
	const client = useClientInfo(user.clientID)
	const [shouldSend, setShouldSend] = useState(false)
	const [hasChanged, setHasChanged] = useState(false)
	const [modules, refresh] = useModuleAssignments(user.clientID)
	const { setMessage } = useStatusMessage()
	function addNewContentRow() {
		setHasChanged(true)
		setList([
			...list,
			{ newContent, id: String(Math.floor(Math.random() * 1000000)) },
		])
	}
	useEffect(() => {
		refresh()
	}, [user.clientID])
	useEffect(() => {
		// console.log('inside user', user)
		// axios
		//   .get(`${BASE_URL}/auth/client?_id=${user.clientID}`)
		//   .then((res) => {
		// 		console.log(res)
		//     const content = res.data[0].data;
		//     if (!content) {
		//       return;
		//     }
		//     setList(JSON.parse(content));
		//   })
		//   .catch(console.log);
		if (client.data) {
			setList(JSON.parse(client.data))
		}
	}, [client])
	function deleteContent(id) {
		const newList = list.filter((item) => item.id !== id)
		console.log('id', id)
		if (list.length === 1) {
			setList([newContent])
		} else {
			setList([...newList])
		}
	}

	function editContent(e, item, index) {
		const name = e.target.name
		const value = e.target.value
		setHasChanged(true)
		setList((prevList) => {
			const newItem = { ...item }
			newItem[name] = value

			const newList = [...prevList]
			newList[index] = newItem
			newList[index].lastEdited = new Date().toLocaleString()

			return newList
		})
	}
	let timeout = useRef()
	// useEffect(() => {
	// 	if (!hasChanged) return
	// 	if (!list.length) return
	// 	const inp = document.querySelector('tr:last-child td input')
	// 	if (inp.value === '0') inp.focus()
	// 	setShouldSend(false)
	// 	clearTimeout(timeout.current)
	// 	timeout.current = setTimeout(() => {
	// 		setShouldSend(true)
	// 		setMessage('Saved content...')
	// 	}, 2000)
	// }, [list])
	const saveData = () => {
		const jsonData = { data: JSON.stringify(list) }
			// console.log(user);
			axios
				.patch(`${BASE_URL}/client/content/${user.clientID}`, jsonData)
				// .then(console.log)
				.catch(console.log)
	}
	// useEffect(() => {
	// 	if (shouldSend) {
	// 		const jsonData = { data: JSON.stringify(list) }
	// 		// console.log(user);
	// 		axios
	// 			.patch(`${BASE_URL}/client/content/${user.clientID}`, jsonData)
	// 			// .then(console.log)
	// 			.catch(console.log)
	// 	}
	// }, [shouldSend])
	console.log(modules)
	return (
		<div className={css(styles.container)}>
			<div className={css(styles.flexBlock)}>
				<BreadCrumb items={[{ name: 'EDITABLE CONTENT' }]} />
				<div style={{display:'flex'}}>
					<Button clickHandler={saveData}>SAVE</Button>
					<Button clickHandler={addNewContentRow}>ADD NEW ROW</Button>

				</div>
			</div>
				<p style={{size:10, margin:2, marginBottom: 15, color:'red'}}>Warning: Please do not edit any values in the table below, this could result in issues on the VR app.</p>
			<table className={css(styles.table)}>
				<thead className={css(styles.thead)}>
					<tr>
						<th className={css(styles.column)}>Module</th>
						<th className={css(styles.column)}>Name</th>
						<th className={css(styles.column)}>Content</th>
						<th className={css(styles.column)}>Last Edited</th>
						<th className={css(styles.IconHeader)}></th>
					</tr>
				</thead>
				<tbody className={css(styles.tbody)}>
					{list.map((item, index) => (
						<tr key={item.uuid} className={css(styles.row)}>
							<td className={css(styles.bodyCell)}>
								{/* <TextInput
                  inputName="id"
                  defaultValue={item.id}
                  customInputStyles={styles.input}
                  onChange={(e) => editContent(e, item, index)}
                /> */}
								<DropDown
									items={[
										'default',
										...modules.map((mod) => mod.name),
									]}
									customInputStyles={styles.dropDown}
									value={item.module}
									onChange={(mod) =>
										setList((list) => {
											list[index].module = mod
											return [...list]
										})
									}
								/>
							</td>
							<td className={css(styles.bodyCell)}>
								<TextInput
									inputName="name"
									defaultValue={item.name}
									customInputStyles={styles.input}
									onChange={(e) =>
										editContent(e, item, index)
									}
								/>
							</td>
							<td className={css(styles.bodyCell)}>
								{/* <TextInput
									inputName="moduleCategory"
									defaultValue={item.moduleCategory}
									customInputStyles={styles.input}
									onChange={(e) =>
										editContent(e, item, index)
									}
								/> */}
								<textarea
									onChange={(e) =>
										editContent(e, item, index)
									}
									name="content"
									defaultValue={item.moduleCategory}
									className={css(styles.textarea)}
								></textarea>
							</td>
							<td className={css(styles.bodyCell)}>
								<TextInput
									inputName="lastEdited"
									value={item.lastEdited}
									customInputStyles={styles.input}
								/>
							</td>
							<td
								className={css(
									styles.bodyCell,
									styles.deleteCell
								)}
							>
								<DeleteButton
									clickHandler={() => deleteContent(item.id)}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

export default ContentManagement

const styles = StyleSheet.create({
	container: {
		paddingLeft: '3rem',
		paddingRight: '2rem',
	},
	flexBlock: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	table: {
		width: '100%',
		background: 'rgba(251, 206, 7, 1)',
		border: '2px solid rgba(251, 206, 7, 1)',
		borderSpacing: '0',
		borderRadius: 15,
		overflow: 'hidden',
	},
	thead: {
		textAlign: 'left',
		display: 'block',
	},
	row: {
		display: 'block',
		width: '100%',
	},
	dropDown: {
		width: '100%',
	},
	tbody: {
		background: '#fff',
		display: ' block',
		overflowY: 'auto',
		maxHeight: 400,
		width: '100%',
		'::-webkit-scrollbar': {
			width: '.5rem',
			background: 'rgba(100, 100, 100, 1)',
			position: 'absolute',
			display: 'block',
		},
		'::-webkit-scrollbar-thumb': {
			background: 'rgba(251, 206, 7, 1)',
			borderRadius: 5,
		},
	},
	bodyCell: {
		display: 'inline-block',
		padding: '1rem 0',
		textAlign: 'center',
		width: '24%',
	},
	deleteCell: {
		width: 25,
		padding: '1.5rem .7rem',
	},
	column: {
		padding: '1.5rem .5rem',
		width: '24.5%',
	},
	IconHeader: {
		width: 25,
		padding: '1.5rem 0.7rem',
	},
	input: {
		boxSizing: 'border-box',
		width: '95%',
	},
	textarea: {
		background: 'rgb(217, 217, 217)',
		width: '95%',
		outline: 0,
		borderRadius: 10,
		border: 0,
		padding: '.5rem',
		boxSizing: 'border-box',
	},
})
