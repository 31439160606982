import { css, StyleSheet } from 'aphrodite'
import { testUsername } from '../helpers/testUsername'
import FormUserTips from './FormUserTips'

const TextInput = ({
  labelText,
  customLabelStyles,
  customInputStyles,
  required,
  defaultValue,
  value,
  onChange,
  inputName,
  readOnly,
  password,
  id,
  userTips,
  pattern,
  validateSpecialChar,
}) => {
  return labelText ? (
    <div style={{ display: 'block' }}>
      <label className={css(styles.label, customLabelStyles)}>
        <p> {labelText} </p>
        <input
          className={css(styles.bar, customInputStyles)}
          name={inputName}
          type={password ? 'password' : 'type'}
          required={required}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange && onChange}
          readOnly={readOnly}
          id={id != null && id}
          pattern={pattern && pattern}
        />
      </label>
      {userTips && <FormUserTips id={id} />}
    </div>
  ) : (
    <div>
      <input
        className={css(styles.bar, customInputStyles)}
        name={inputName}
        type="text"
        required={required}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange && onChange}
        readOnly={readOnly}
        id={id != null && id}
      />
      {userTips && <FormUserTips id={id} />}
    </div>
  )
}

export default TextInput

const styles = StyleSheet.create({
  bar: {
    // display:'block',
    border: 0,
    outline: 0,
    background: 'rgba(217, 217, 217, 1)',
    padding: '.5rem',
    borderRadius: '.5rem',
  },
  label: {
    display: 'block',
    fontWeight: 600,
    // textAlign: 'left'
  },
})
