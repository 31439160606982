import { css, StyleSheet } from 'aphrodite'
import { useEffect, useRef, useState } from 'react'
import { useClientInfo } from '../auth/hooks/useUserInfo'
import React from 'react'
import axios from 'axios'
import { BASE_URL } from '../constants'
import { Link, useNavigate } from 'react-router-dom'

const Box = (props) => {
  const user = JSON.parse(localStorage.getItem('userDetails')).user
  const client = useClientInfo(user.clientID)
  const ref = useRef()
  const navigate = useNavigate()
  // useEffect(() => {
  //   setTimeout(() => window.addEventListener('click', listener), 500)
  //   let listener = (e) => {
  //     console.log('listener', ref.current)
  //     // if(!ref.current.contains(e.target)) props.unMount()
  //   }

  //   return () => window.removeEventListener('click', listener)
  // }, [])

  const logout = async () => {
    // localStorage.setItem('userToken', '')
    // localStorage.setItem('userTimestamp', '')
    // localStorage.setItem('userDetails', '')
    // setIsLoggedIn(false)
    const response = await axios.post(`${BASE_URL}/auth/user/logout`)
    localStorage.clear()
    navigate('/login')
  }

  return (
    <div {...props} ref={ref} className={css(styles.box)}>
      <p className={css(styles.info)}>Role: {user?.roles}</p>
      <p className={css(styles.info)}>User: {user?.userName}</p>
      <p className={css(styles.info)}>Client: {client?.clientName}</p>
      {/* <p
        onClick={async () => {
          const response = await axios.get(`${BASE_URL}/auth/user/logout`)
          alert(response.data.message)
          localStorage.clear()
        }}
        href=""
      >
        Log out
      </p> */}
      <button
        onClick={logout}
        style={{
          padding: 0,
          margin: 0,
          cursor: 'pointer',
          background: 'none',
          outline: 'none',
          border: 'none',
          fontSize: '2vh',
          fontWeight: 'bold',
        }}
      >
        Log out
      </button>
    </div>
  )
}
const UserAvatarIcon = () => {
  const [show, setShow] = useState()
  const ref = useRef()
  const button = useRef()

  return (
    <div>
      <svg
        // ref={button}
        onClick={() => setShow(!show)}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={css(styles.icon)}
      >
        <path
          d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 4C11.93 4 13.5 5.57 13.5 7.5C13.5 9.43 11.93 11 10 11C8.07 11 6.5 9.43 6.5 7.5C6.5 5.57 8.07 4 10 4ZM10 18C7.97 18 5.57 17.18 3.86 15.12C5.6116 13.7457 7.77362 12.9988 10 12.9988C12.2264 12.9988 14.3884 13.7457 16.14 15.12C14.43 17.18 12.03 18 10 18Z"
          fill="black"
        />
      </svg>
      {show && <Box unMount={() => setShow(false)} />}
    </div>
  )
}
const styles = StyleSheet.create({
  box: {
    width: 230,
    height: 145,
    right: 30,
    background: 'white',
    borderRadius: 5,
    padding: 10,
    position: 'absolute',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
  },
  icon: {
    cursor: 'pointer',
  },
  info: {
    fontSize: 14,
  },
})
export default UserAvatarIcon
