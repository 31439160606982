import axios from 'axios'
import React, { useState } from 'react'
import { CSVDownload } from 'react-csv'
import { BASE_URL } from '../../constants'
import Button from '../../uiElements/Button'
import { DropDown } from '../../uiElements/DropDown'
import { StyleSheet, css } from 'aphrodite'
import { Description } from '../../uiElements/Description'
import { userUserInfo } from '../../auth/hooks/useUserInfo'

export const Other = () => {
  const [timePeriod, setTimePeriod] = useState()
  const timePeriods = [6, 3, 1]
  const [logs, setLogs] = useState()
  const user = userUserInfo()

  const fetchLogs = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/logs`)
      const logs = response.data.map((logs) => {
        delete logs.affectedUser
        delete logs.performedByUser
        delete logs.updatedAt
        delete logs.__v
        return logs
      })
      setLogs(logs)
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <div className={css(styles.page)}>
      <div className={css(styles.flexbox)}>
        {/* //! removed logs for admin only */}
        {user.roles != 'admin' && (
          <div className={css(styles.boxes)}>
            <h2 className={css(styles.heading)}>Logs</h2>
            <Description
              text={'Export logs as a CSV file, in given time period.'}
            />
            <DropDown
              value={timePeriod}
              items={timePeriods.map((per) => per + ' months')}
              onChange={(val) => setTimePeriod(val)}
            />
            <Button clickHandler={fetchLogs}>Export</Button>
            {logs && <CSVDownload data={logs} target="__blank" />}
          </div>
        )}

        <div className={css(styles.boxes)}>
          <h2 className={css(styles.heading)}>App Download</h2>
          <Description text="Download the latest application from the link below, this link will also work with Shell MDM for Meta Quest" />
          <a
            // style={{ marginLeft: 10 }}
            href={' https://demo.autovrse.app/shell/SRM_0.37.apk'}
          >
            {' '}
            https://demo.autovrse.app/shell/SRM_0.37.apk
          </a>
        </div>
        <div className={css(styles.boxes)}>
          <h2 className={css(styles.heading)}>Documentation</h2>
          <Description text="Use the link below to visit the complete documentation for the Shell Reactor Internal VR Application" />
          <a
            target="__blank"
            // style={{ marginLeft: 10 }}
            href={
              ' https://www.notion.so/Shell-Reactor-Internals-Documentation-100c65e3c2824dc999d2f57db4333843'
            }
          >
            {' '}
            https://www.notion.so/Shell-Reactor-Internals-Documentation-100c65e3c2824dc999d2f57db4333843
          </a>
        </div>
        <div className={css(styles.logoContainer)}>
          Powered By
          <img src="/autovrseLogo.png" className={css(styles.logo)} />
        </div>
      </div>
    </div>
  )
}

const styles = StyleSheet.create({
  logoContainer: {
    position: 'absolute',
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    // width: '100%',
    bottom: '5vh',
    right: '5vh',
    justifyContent: 'flex-end',
    // right: '-5vh',
    // right: '-5vh',
  },
  logo: {
    width: ' 18%',
    height: 'auto',
    marginLeft: 16,
  },
  page: {
    padding: 50,
  },
  flexbox: {
    // width:'80%',
    // justifyContent:'space-between',
    display: 'flex',
    flexWrap: 'wrap',
  },
  boxes: {
    width: '40%',
    // flexGrow: '1',
    marginRight: '4%',
    marginBottom: '4%',
    border: '0.3vh solid rgb(219, 225, 232)',
    borderRadius: '0.5vh',
    padding: '5vh',
  },
  heading: {
    // marginLeft: '15px',
    margin: 0,
    // color: 'rgba(221, 29, 33, 1)',
  },
})
