import { useEffect, useState } from 'react'
import HeaderButton from './HeaderButton'
import { css, StyleSheet } from 'aphrodite'
import UserAvatarIcon from '../svgs/UserAvatarIcon'
import GearIcon from '../svgs/GearIcon'
import { useData } from '../store'
import { getUserData } from '../auth/getUserData'
import { useClientInfo, userUserInfo } from '../auth/hooks/useUserInfo'

const Header = () => {
  const { currentPage, setCurrentPage } = useData()
  const user = userUserInfo()
  // const client = useClientInfo(user.clientID)
  return (
    <header className={css(styles.header)}>
      <img
        src={'./shelllogo.png'}
        height="40px"
        style={{ marginRight: '2rem', marginLeft: '0px' }}
      />
      {user.roles === 'superAdmin' && ( // superadmin here because content-amangement same for all clients
        <>
          <HeaderButton
            clickHandler={() => setCurrentPage('super admins')}
            active={currentPage === 'super admins'}
          >
            SUPER ADMINS
          </HeaderButton>
          <HeaderButton
            clickHandler={() => setCurrentPage('clients')}
            active={currentPage === 'clients'}
          >
            CLIENT MANAGEMENT
          </HeaderButton>
        </>
      )}
      <HeaderButton
        clickHandler={() => setCurrentPage('user profiles')}
        active={currentPage === 'user profiles'}
      >
        USER PROFILES
      </HeaderButton>
      {user?.roles === 'superAdmin' && (
        <>
          <HeaderButton
            clickHandler={() => setCurrentPage('devices')}
            active={currentPage === 'devices'}
          >
            DEVICES
          </HeaderButton>
          <HeaderButton
            clickHandler={() => setCurrentPage('content management')}
            active={currentPage === 'content management'}
          >
            CONTENT MANAGEMENT
          </HeaderButton>
        </>
      )}
      {(user.roles === 'superAdmin' || user.roles === 'admin') && (
        <HeaderButton
          clickHandler={() => setCurrentPage('other')}
          active={currentPage == 'other'}
        >
          OTHER
        </HeaderButton>
      )}
      {/* //! Niyati */}
      {/* {user?.roles === 'superAdmin' && (
        <>
          <HeaderButton
            clickHandler={() => setCurrentPage('clients')}
            active={currentPage === 'clients'}
          >
            CLIENT MANAGEMENT
          </HeaderButton> */}
      {/* <HeaderButton
						clickHandler={() => setCurrentPage('license')}
						active={currentPage === 'license'}
					>
						LICENSE MANAGEMENT
					</HeaderButton>
					<HeaderButton
						clickHandler={() => setCurrentPage('analytics')}
						active={currentPage === 'analytics'}
					>
						ANALYTICS
					</HeaderButton>
					<HeaderButton
						clickHandler={() => setCurrentPage('support')}
						active={currentPage === 'support'}
					>
						SUPPORT
					</HeaderButton> */}
      {/* </>
      )} */}
      {/* //* Niyati */}
      {/* {user.roles === 'superAdmin' && (
        <HeaderButton
          clickHandler={() => setCurrentPage('devices')}
          active={currentPage === 'devices'}
        >
          DEVICES
        </HeaderButton>
      )}
      <HeaderButton
        clickHandler={() => setCurrentPage('user profiles')}
        active={currentPage === 'user profiles'}
      >
        USER PROFILES
      </HeaderButton> */}
      {/* <HeaderButton
				clickHandler={() => setCurrentPage('evaluation')}
				active={currentPage === 'evaluation'}
			>
				EVALUATION
			</HeaderButton> */}
      {/* <HeaderButton
				clickHandler={() => setCurrentPage('reports')}
				active={currentPage === 'reports'}
			>
				REPORTS
			</HeaderButton> */}

      {/* <HeaderButton
				clickHandler={() => setCurrentPage('modules')}
				active={currentPage === 'modules'}
			>
				TRAINING MODULES
			</HeaderButton> */}
      <div className={css(styles.metaContainer)}>
        <UserAvatarIcon />
        {/* <GearIcon /> */}
      </div>
    </header>
  )
}

export default Header

const styles = StyleSheet.create({
  header: {
    borderBottom: '3px solid rgba(221, 29, 33, 1)',
    display: 'flex',
    padding: '1rem 2rem 0',
  },
  metaContainer: {
    display: 'inline-block',
    display: 'flex',
    alignSelf: 'center',
    marginLeft: 'auto',
    gap: '1.5rem',
  },
})
