import SearchIcon from '../svgs/SearchIcon'
import { css, StyleSheet } from 'aphrodite'

const SearchBar = ({ changeHandler, customStyles }) => {
  return (
    <form
      className={css(styles.container, customStyles)}
      onSubmit={(e) => {
        e.preventDefault()
        if (e.key === 'Enter') {
          return false
        } else {
          return false
        }
      }}
    >
      <SearchIcon />
      <input
        className={css(styles.bar)}
        type="search"
        required
        onChange={changeHandler}
      />
    </form>
  )
}

export default SearchBar

const styles = StyleSheet.create({
  container: {
    background: 'rgba(217, 217, 217, 1)',
    padding: '.5rem',
    borderRadius: '.5rem',
    display: 'flex',
  },
  bar: {
    border: 0,
    outline: 0,
    background: 'transparent',
    marginLeft: '.5rem',
    width: '100%',
  },
})
