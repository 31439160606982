import axios from 'axios'
import { useEffect, useState } from 'react'
import { BASE_URL, SUPER_ADMIN_TOKEN } from '../../constants'

export const useClientUsers = (clientID, query = '') => {
  const [users, setUsers] = useState([])
  const fetch = () => {

    axios.get(`${BASE_URL}/client/${clientID}/users${query}`).then((users) => {
      setUsers(users.data)
    })
  }
  useEffect(() => {
    if(!clientID)return
    fetch()
  }, [clientID, query])
  return [users, fetch]
}


export const useClientList = (query = '') => {
  const [listData, setListData] = useState([])
  const clientID = JSON.parse(localStorage.getItem('userDetails')).user.clientID
  useEffect(() => {
    const fetchList = async () => {
      const res = await axios.get(
        `${BASE_URL}${`/client/${clientID}/users?${query}`}`
      )
      console.log('res', res.data)
      setListData(res.data)
    }
    fetchList()
    // console.log(users)
  }, [query])
  return listData
}
