import create from 'zustand'

export const useData = create((set) => ({
  currentPage: 'user profiles',
  activeClientSubPage: 'main',
  activeClient: {},
  activeUserProfile: {},
  activeUserProfileSubPage: 'main',
  activeEvaluationPage: 'main',
  activeUserName: '',
  activeModuleName: '',
  activeSuperAdminSubPage: 'main',
  setCurrentPage: (page) => set({ currentPage: page }),
  setActiveClientSubPage: (subPage) => set({ activeClientSubPage: subPage }),
  setActiveClient: (client) => set({ activeClient: client }),
  setActiveUserProfile: (userProfile) =>
    set({ activeUserProfile: userProfile }),
  setActiveUserProfileSubPage: (subPage) =>
    set({ activeUserProfileSubPage: subPage }),
  setActiveEvaluationPage: (subPage) => set({ activeEvaluationPage: subPage }),
  setActiveSuperAdminSubPage: (subPage) =>
    set({ activeSuperAdminSubPage: subPage }),
}))

export const useModal = create((set) => ({
  component: null,
  setComponent: (comp) => {
    set({ component: comp })
  },
}))
let timeout
export const useStatusMessage = create((set) => ({
  message: '',
  setMessage: (msg) => {
    set({ message: msg })
    timeout && clearTimeout(timeout)
    timeout = setTimeout(() => {
      set({ message: '' })
    }, 3000)
  },
}))
