import { css, StyleSheet } from 'aphrodite'
import axios from 'axios'
import { useEffect, useState } from 'react'
import uuid from 'react-uuid'
import { userUserInfo } from '../../auth/hooks/useUserInfo'
import { BASE_URL, SUPER_ADMIN_TOKEN } from '../../constants'
import { useData } from '../../store'
import BreadCrumb from '../../uiElements/BreadCrumb'
import Button from '../../uiElements/Button'
import { Description } from '../../uiElements/Description'
import { DropDown } from '../../uiElements/DropDown'
import SecondaryList from '../../uiElements/SecondaryList'
import { AddUsers } from './AddUsers'
import SingleUserProfile from './SingleUserProfile'

const UserProfiles = () => {
	const { activeUserProfileSubPage, setActiveUserProfileSubPage } = useData()
	const userDetails = JSON.parse(localStorage.getItem('userDetails'))
	const { activeUserProfile, setActiveUserProfile } = useData()
	const [clients, setClients] = useState([])
	const INITIAL_CLIENTS_DROPDOWN_ITEM = {
		label: 'clientName',
		value: 'clientID',
	}
	const [clientsDropdownItems, setClientsDropdownItems] = useState([
		INITIAL_CLIENTS_DROPDOWN_ITEM,
	])
	const [selectedClientID, setSelectedClientID] = useState(
		userDetails.user.roles !== 'superAdmin' && userDetails.user.clientID
	)
	// console.log('userDetails in UserProfiles', userDetails)

	function titleClickHandler(user) {
		console.log('user', user)
		setActiveUserProfile({ ...user })
	}
	// console.log('client', client)

	const deleteUser = async (user) => {
		const config = {
			headers: {
				authorization: `Bearer ${localStorage.getItem('userToken')}`,
			},
		}
		if (!confirm(`Are you sure you want to delete user: ${user.userName}`))
			return
		const userID = user._id
		const res = await axios.delete(`${BASE_URL}/auth/user/${userID}`)
		console.log('res', res.data)
		console.log('user', user, user._id)
	}
	useEffect(
		function setDefaultClientID() {
			if (userDetails.roles === 'admin') {
				setSelectedClientID(userDetails.clientID)
			}
		},
		[userDetails]
	)
	useEffect(() => {
		async function fetchClients() {
			const response = await axios.get(`${BASE_URL}/client`)
			// console.log('response', response, response.data)
			setClients(response.data)
		}
		fetchClients()
	}, [])

	useEffect(() => {
		const items = [{ label: '----CLIENT NAME----', value: '' }]
		if (clients.length) {
			clients.forEach((client) => {
				const singleClient = {
					label: client.clientName,
					value: client._id,
				}
				items.push(singleClient)
			})
		}
		setClientsDropdownItems([...items])
		// console.log('clients in UserProfiles', clients, '\nitems\n', items)
	}, [clients])

	function displayPage() {
		switch (activeUserProfileSubPage) {
			case 'main':
				return (
					<>
						<header className={css(styles.flexBlock)}>
							<BreadCrumb items={[{ name: 'USERS' }]} />
							{selectedClientID && (
								<Button
									clickHandler={() =>
										setActiveUserProfileSubPage('add users')
									}
								>
									ADD NEW USER
								</Button>
							)}
						</header>
						<Description
							text={
								'In this section you will be able to create or delete accounts for Trainers or Trainees. These accounts will be used in the Shell Reactor Internals VR Application.'
							}
						/>
						<Description
							text={'Select a user to change their role'}
						/>
						{userDetails.user.roles === 'superAdmin' && (
							<>
								<Description
									text={
										'Please select a client to get trainees & trainers of that client'
									}
								/>
								<DropDown
									labelText="Clients"
									items={clientsDropdownItems}
									customInputStyles={styles.clients}
									value={selectedClientID}
									onChange={(clientID) =>
										setSelectedClientID(clientID)
									}
								/>
							</>
						)}
						{clients.length && selectedClientID && (
							<div className={css(styles.flexBlock)}>
								<SecondaryList
									key={uuid()}
									title="TRAINERS"
									sort
									fetchURL={`/client/${selectedClientID}/users?roles=trainer`}
									ItemTitleProp="userName"
									customListStyles={styles.list}
									customListHeaderStyles={styles.listHeader}
									customListBodyStyles={styles.listBody}
									titleClickHandler={titleClickHandler}
									closeClickHandler={deleteUser}
									customSingleItemStyles={
										styles.listSingleItem
									}
								/>
								<SecondaryList
									key={uuid()}
									title="TRAINEES"
									sort
									fetchURL={`/client/${selectedClientID}/users?roles=trainee`}
									ItemTitleProp="userName"
									customListStyles={styles.list}
									customListHeaderStyles={styles.listHeader}
									customListBodyStyles={styles.listBody}
									titleClickHandler={titleClickHandler}
									closeClickHandler={deleteUser}
									customSingleItemStyles={
										styles.listSingleItem
									}
								/>
							</div>
						)}
					</>
				)
			case 'add users':
				return (
					<AddUsers
						key={uuid()}
						selectedClientID={selectedClientID}
					/>
				)
			case 'single user profile':
				return <SingleUserProfile />
		}
	}

	useEffect(() => {
		if (Object.keys(activeUserProfile).length) {
			setActiveUserProfileSubPage('single user profile')
		} else {
			setActiveUserProfileSubPage('main')
		}
	}, [activeUserProfile])

	return <div className={css(styles.container)}>{displayPage()}</div>
}

export default UserProfiles

const styles = StyleSheet.create({
	container: {
		paddingLeft: '3rem',
		paddingRight: '2rem',
	},
	flexBlock: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	list: {
		width: '48%',
		borderRadius: 10,
		background: 'rgba(251, 206, 7, 1)',
		padding: 0,
		border: '3px solid rgba(251, 206, 7, 1)',
	},
	listHeader: {
		padding: '1rem 1rem 0px',
	},
	listBody: {
		background: '#fff',
		paddingLeft: '1rem',
	},
	listSingleItem: {
		border: '2px solid rgba(219, 225, 232, 1)',
		margin: '1rem .5rem',
		borderRadius: 20,
		':hover': {
			background: 'red',
			border: '2px solid red',
			color: '#fff',
			':nth-child(n) button': {
				color: '#fff',
			},
			':nth-child(n) svg': {
				fill: '#fff',
				filter: 'invert(100%)',
			},
		},
	},
	clients: {
		width: '15rem',
		marginBottom: '1rem',
	},
})
