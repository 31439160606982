import { css, StyleSheet } from 'aphrodite'
import axios from 'axios'
import { useState } from 'react'
import uuid from 'react-uuid'
import { getUserData } from '../../auth/getUserData'
import { useClientInfo, userUserInfo } from '../../auth/hooks/useUserInfo'
import { BASE_URL } from '../../constants'
import { testUsername } from '../../helpers/testUsername'
import { useData, useModal, useStatusMessage } from '../../store'
import BreadCrumb from '../../uiElements/BreadCrumb'
import Button from '../../uiElements/Button'
import { DropDown } from '../../uiElements/DropDown'
import TextInput from '../../uiElements/TextInput'

export const ResponseModal = ({ otp }) => {
  return (
    <div className={css(styles.otpContainer)}>
      {otp && (
        <sub>
          <b>New OTP successfully generated</b>
        </sub>
      )}
      <h1 style={{ color: 'blue', fontSize: '3rem' }}>
        {otp || 'Created User'}
      </h1>
      {otp && (
        <sub>Please keep the above OTP safely, it will expire in 1 week</sub>
      )}
    </div>
  )
}
export const AddUsers = ({ selectedClientID }) => {
  const { setActiveUserProfileSubPage } = useData()
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const user = userUserInfo()
  const client = useClientInfo(user.clientID)
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('trainee')
  const [pincode, setPincode] = useState('')
  const { setComponent } = useModal()
  const { setMessage } = useStatusMessage()

  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      // const { clientID } = await getUserData()

      const newEntry = {
        clientID: selectedClientID,
        userName,
        ...(email ? { email } : {}),
        roles: role,
        pincode,
      }

      const response = await axios.post(
        `${BASE_URL}/auth/user/register`,
        newEntry
      )
      setMessage(
        `${role[0].toUpperCase() + role.slice(1, role.length)} created...`
      )
      setMessage(`Invite successfully sent to ${email}`)
      console.log('response', response)
      const otp = response.data.otp
      setUserName('')
      setEmail('')
      setPassword('')
      // setComponent(<ResponseModal otp={otp} />)
    } catch (err) {
      setMessage(err.response.data.message)
      console.log(err)
    }
  }
  // console.log(userName, role, client)
  return (
    <div className={css(styles.page)}>
      <BreadCrumb
        items={[
          {
            name: 'USERS',
            clickHandler: () => setActiveUserProfileSubPage('main'),
          },
          { name: 'ADD USER' },
        ]}
      />
      <div className={css(styles.container)}>
        <div className={css(styles.add)}>
          <form onSubmit={onSubmit}>
            {/* <h3>Create User</h3> */}
            <TextInput
              labelText="Username"
              customInputStyles={styles.input}
              value={userName}
              required
              onChange={(e) => setUserName(e.target.value)}
              id="user_name"
              userTips
              pattern="(.){5,20}"
              // validateSpecialChar={(e) => testUsername(e)}
            />
            <TextInput
              labelText="Email"
              customInputStyles={styles.input}
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            {/* <TextInput
							labelText={"Password"}
							customInputStyles={styles.input}
							value={password}
							required
							onChange={(e) =>setPassword(e.target.value)}
						/> */}
            <DropDown
              labelText="USER ROLE"
              value={role}
              onChange={(val) => setRole(val)}
              customInputStyles={styles.dropdown}
              items={['trainee', 'trainer']}
            />
            <Button
              customStyles={styles.button}
              disabled={!testUsername(userName)}
            >
              Invite
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}

const styles = StyleSheet.create({
  page: {
    // paddingLeft: '4rem',
  },
  container: {
    borderRadius: '15px',
    boxShadow: '1px 1px 3px 0px #00000026',
    width: '24rem',
  },
  input: {
    width: '20rem',
    boxShadow: '1px 1px 3px 0px #00000026',
    background: 'white',
  },
  dropdown: {
    width: '20.7rem',
  },
  button: {
    marginTop: '2rem',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  add: {
    display: 'flex',
    gap: '2rem',
  },
  otpContainer: {
    padding: '3rem',
    borderRadius: '15px',
    border: 'black solid 5px',
    textAlign: 'center',
    background: 'white',
  },
})
