import axios from 'axios'
import { useEffect, useState } from 'react'
import { BASE_URL } from '../../constants'
import { getUserData } from '../getUserData'

export const userUserInfo = (id) => {
  const [userInfo, setUserInfo] = useState({})
  useEffect(() => {
    if (id) {
      console.log('inside', id)
      axios
        .get(`${BASE_URL}/auth/user/${id}`)
        .then(({ data }) => {
          console.log('insidedata ', data)

          setUserInfo(data.user)
        })
        .catch(console.log)
      return
    }
    getUserData().then((user) => {
      setUserInfo(user)
    })
  }, [id])
  return userInfo
}
export const useClientInfo = (clientID) => {
  const [clientInfo, setClientInfo] = useState({})
  useEffect(() => {
    if (!clientID) return
    axios
      .get(`${BASE_URL}/auth/client?_id=${clientID}`)
      .then((res) => {
        if (res.data && res.data[0]) {
          setClientInfo(res.data[0])
        }
      })
      .catch(console.log)
  }, [clientID])
  return clientInfo
}
