const fetchLocally = false
export const BASE_URL =
	!fetchLocally || process.env.NODE_ENV === 'production'
		? 'https://shell.api.autovrse-training.com'
		: 'http://localhost:5000'

// https://13.235.201.197

export const SUPER_ADMIN_TOKEN =
	'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRJRCI6IjYyYzJhODc5MjE4YWQ2MzdkMTEzYmIzMiIsInVzZXJOYW1lIjoiYWRtaW5fYXV0b3Zyc2UiLCJyb2xlcyI6InN1cGVyQWRtaW4iLCJpZCI6IjYyZDk0YjkyMTcwNjRkZmRlZmQzZTg3NCIsImlhdCI6MTY1ODQwNzg0NCwiZXhwIjoxNjYwOTk5ODQ0fQ.bqTy9pPwxz_3UPx2f5WNp22I0dY2S0q63gAI38br4K4'
