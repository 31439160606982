import { useEffect, useState } from 'react'
import ListHeader from '../../uiElements/ListHeader'
import ListItem from '../../uiElements/ListItem'
import uuid from 'react-uuid'
import NewClient from './NewClient'
import ClientInfo from './ClientInfo'
import { useData } from '../../store'
import ModuleAccess from './ModuleAccess'
import {Description} from '../../uiElements/Description'
import AddAdmins from './AddAdmins'
import axios from 'axios'
import { BASE_URL, SUPER_ADMIN_TOKEN } from '../../constants'

const Clients = () => {
	/* CLIENTS DATA FOR TESTING */
	const {
		activeClient,
		setActiveClient,
		activeClientSubPage,
		setActiveClientSubPage,
	} = useData()

	const [clientsData, setClientsData] = useState([])

	useEffect(() => {
		const fetchClients = async () => {
			const config = {
				headers: { authorization: `Bearer ${SUPER_ADMIN_TOKEN}` },
			}
			const res = await axios.get(`${BASE_URL}/client`)
			console.log('res', res.data)
			setClientsData(res.data.reverse())
		}
		fetchClients()
	}, [activeClientSubPage])

	useEffect(() => {
		if (activeClientSubPage === 'main') setActiveClient('')
	}, [activeClientSubPage])

	function displayPage() {
		switch (activeClientSubPage) {
			case 'main':
				return (
					<>
						{/* <div style={{marginLeft:40}}>
							<Description text={''} />
						</div> */}
						<ListHeader
							title="CLIENTS"
							btnContent="CREATE CLIENT"
							clickHandler={() =>
								setActiveClientSubPage('create')
							}
							description={'The section below is used to create or edit new clients accounts, each client is a set of admins, trainers and trainees. You will be able to create accounts for each of them within this section as well.'}
						/>
						{clientsData.map((client) => (
							<ListItem
								key={uuid()}
								title={client.clientName}
								clickHandler={() => {
									setActiveClient(client)
								}}
							/>
						))}
					</>
				)
			case 'create':
				return <NewClient />
			case 'info':
				return <ClientInfo />
			case 'module access':
				return <ModuleAccess />
			case 'add admins':
				return <AddAdmins />
		}
	}

	useEffect(() => {
		activeClient.clientName && setActiveClientSubPage('info')
	}, [activeClient])

	return <div>{displayPage()}</div>
}

export default Clients
