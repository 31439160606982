import BreadCrumb from '../../uiElements/BreadCrumb'
import Submit from '../../uiElements/Submit'
import TextInput from '../../uiElements/TextInput'
import { css, StyleSheet } from 'aphrodite'
import Button from '../../uiElements/Button'
import { useData, useStatusMessage } from '../../store'
import { AddUsers } from '../userProfiles/AddUsers'
import { useState } from 'react'
import axios from 'axios'
import { BASE_URL } from '../../constants'
import { testUsername } from '../../helpers/testUsername'

const NewClient = () => {
  const { setActiveClientSubPage } = useData()
  const { setMessage } = useStatusMessage()
  const [name, setName] = useState('')
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await axios.post(`${BASE_URL}/auth/client/register`, { clientName: name })
      setMessage('Client register successfully...')
      setActiveClientSubPage('main')
    } catch (err) {
      setMessage(`Something went wrong: ${err.response?.message}`)
    }
  }
  return (
    <div className={css(styles.container)}>
      <div>
        <BreadCrumb
          items={[
            {
              name: 'CLIENT LIST',
              clickHandler: () => setActiveClientSubPage('main'),
            },
            { name: 'CREATE NEW CLIENT' },
          ]}
        />
        <form onSubmit={handleSubmit}>
          <TextInput
            labelText="CLIENT NAME"
            customInputStyles={styles.input}
            value={name}
            onChange={(e) => setName(e.target.value)}
            userTips
            pattern="(.){5,20}"
            id="client_name"
          />
          {/* <TextInput
						labelText="TEAM NAME"
						customInputStyles={styles.input}
					/>
					<TextInput
						labelText="CHOOSE LICENSE"
						customInputStyles={styles.input}
					/> */}
          <Submit
            value="SAVE CLIENT"
            customStyles={styles.submit}
            disabled={!testUsername(name)}
          />
        </form>
      </div>
      {/* <h2>ADD ADMINS</h2>
			<AddUsers />
			<form>
				<h3>ADMIN 2</h3>
				<TextInput
					labelText="Client ID/Username"
					customInputStyles={styles.input}
				/>
				<TextInput
					labelText="EMAIL ID"
					customInputStyles={styles.input}
				/>
				<Button
					clickHandler={() => setActiveClientSubPage('add admins')}
				>
					ADD ADMINS
				</Button>
			</form> */}
    </div>
  )
}

export default NewClient

const styles = StyleSheet.create({
  container: {
    paddingLeft: '3rem',
    paddingRight: '3rem',
  },
  submit: {
    marginTop: '1rem',
    position: 'fixed',
    bottom: 50,
    right: 50,
  },
  input: {
    width: '20%',
  },
})
