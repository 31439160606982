import { css, StyleSheet } from 'aphrodite'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../constants'
import { useData, useStatusMessage } from '../../store'
import BreadCrumb from '../../uiElements/BreadCrumb'
import Button from '../../uiElements/Button'
import { Description } from '../../uiElements/Description'
import TertiaryList from '../../uiElements/TertiaryList'
import NewSuperAdmin from './NewSuperAdmin'

const SuperAdminMainPage = () => {
	const [superAdminsData, setSuperAdminsData] = useState([])
	const clientID = JSON.parse(localStorage.getItem('userDetails')).user
		.clientID
	const clientToken = localStorage.getItem('userToken')

	const { setActiveSuperAdminSubPage } = useData()
	const { setMessage } = useStatusMessage()

	// console.log('clientToken in SuperAdmins', clientToken, clientID)

	useEffect(() => {
		const fetchSuperAdmins = async () => {
			const config = {
				headers: { authorization: `Bearer ${clientToken}` },
			}
			const res = await axios.get(
				`${BASE_URL}/client/${clientID}/users?roles=superAdmin`
			)
			console.log('res', res.data)
			setSuperAdminsData(res.data.reverse())
		}
		fetchSuperAdmins()
	}, [])

	const onDeleteSuperAdmin = async (idx) => {
		console.log(idx, superAdminsData[idx])
		try {
			await axios.delete(
				`${BASE_URL}/auth/user/${superAdminsData[idx]._id}`
			)
			setMessage('Super Admin Deleted Successfully')
			// refresh()
		} catch (err) {
			console.log(err)
			setMessage('Super Admin Delete Failed')
		}
	}

	return (
		<>
			<div className={css(styles.flexBlock)}>
				<BreadCrumb
					items={[
						{
							name: 'SUPER ADMINS',
							clickHandler: '',
						},
					]}
				/>
				<Button
					clickHandler={() =>
						setActiveSuperAdminSubPage('create super admin')
					}
				>
					ADD SUPER ADMIN
				</Button>
			</div>
			<div>
				<Description
					text={
						'In this section you will be able to create and delete new Super Admin accounts, be absolutely sure if you wish to create or delete a Super Admin account,'
					}
				/>
				<Description
					text={
						'if you are not sure what you need to do, please contact your administrator.'
					}
				/>
			</div>
			<TertiaryList
				title="SUPER ADMINS"
				list={superAdminsData.map(
					(superAdmin) =>
						`${superAdmin.userName} - ${superAdmin.email ?? ''}`
				)}
				customStyles={styles.singleList}
				hasDelete
				onDelete={onDeleteSuperAdmin}
			/>
		</>
	)
}

const SuperAdmins = () => {
	const { activeSuperAdminSubPage } = useData()

	function displayPage() {
		switch (activeSuperAdminSubPage) {
			case 'main':
				return <SuperAdminMainPage />
			case 'create super admin':
				return <NewSuperAdmin />
		}
	}

	return <div className={css(styles.container)}>{displayPage()}</div>
}

export default SuperAdmins

const styles = StyleSheet.create({
	container: {
		paddingLeft: '3rem',
		paddingRight: '2rem',
	},
	singleList: {
		width: '50%',
	},
	flexBlock: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
})
