import { css, StyleSheet } from 'aphrodite'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Route, Navigate } from 'react-router'
import { BASE_URL } from '../constants'
import { useStatusMessage } from '../store'
import Submit from '../uiElements/Submit'
import TextInput from '../uiElements/TextInput'

function LoginForm() {
  const [isLoggedIn, setIsLoggedIn] = useState()
  const INITIAL_LOGIN_DATA = {
    userName: '',
    password: '',
  }
  const { setMessage } = useStatusMessage()
  const [userDetails, setUserDetails] = useState(INITIAL_LOGIN_DATA)
  const [OTP, setOTP] = useState('')
  const [showOTP, setShowOTP] = useState(false)
  const HandleInput = (input) => {
    setUserDetails((prev) => {
      const newObj = { ...prev }
      newObj[input.target.name] = input.target.value
      return newObj
    })
    // console.log(userDetails)
  }

  const checkFormData = () => {
    if (userDetails.userName !== '' && userDetails.password !== '') {
      return true
    }
    return false
  }
  const onSendOTP = async () => {
    const formData = { userName: userDetails.userName, otp: OTP }
    // console.log(formData)
    const { data } = await axios.post(
      `${BASE_URL}/auth/user/loginAdmin`,
      formData
    )
    localStorage.setItem('userToken', data.token)
    localStorage.setItem('userTimestamp', new Date().getTime())
    localStorage.setItem('userDetails', JSON.stringify(data))
    axios.defaults.headers.common.authorization = `Bearer ${data.token}`
    setIsLoggedIn(true)
  }
  const onLogin = async () => {
    const config = { headers: { 'Content-type': 'application/json' } }
    const loginData = {
      userName: userDetails.userName,
      password: userDetails.password,
    }
    const loginLink = `${BASE_URL}/auth/user/generateAdminOtp`
    const response = await axios.post(loginLink, loginData, config)
    // console.log(response.data)
    setShowOTP(true)
    setTimeout(() => {
      setOTP('')
      setShowOTP(false)
    }, 60 * 1000)
    // console.log(response)
    // const data = response.data
    // console.log('response.data', data)
  }
  const onClickForgotPassword = async () => {
    if (!userDetails.userName) return setMessage('Please enter your username')
    setMessage(
      `Please check your registered email for ${userDetails.userName}...`
    )
    try {
      const response = await axios.post(
        `${BASE_URL}/auth/user/forgot-password`,
        { userName: userDetails.userName }
      )
      // console.log(response)
    } catch (err) {
      console.log(err)
    }
  }
  const HandleLoginSubmitAsync = async (e) => {
    e.preventDefault()
    // console.log('e', e)
    if (checkFormData()) {
      try {
        if (showOTP) {
          await onSendOTP()
        } else {
          await onLogin()
        }
      } catch (error) {
        setMessage(error?.response?.data?.message)
      }
    } else {
      console.log('invalid userDetails', userDetails)
      setMessage('Please fill valid details')
    }
  }

  return isLoggedIn ? (
    <Navigate to="/" />
  ) : (
    <div className={css(styles.container)}>
      <div className={css(styles.bg)}>
        <img
          className={css(styles.logo)}
          width="25%"
          src="./shelllogo.png"
          alt="Logo"
        />
        <form onSubmit={HandleLoginSubmitAsync} className={css(styles.form)}>
          <h1 className={css(styles.title)}>
            Welcome to VR Training Management
          </h1>
          <p className={css(styles.subTitle)}>
            Please enter your credentials to login
          </p>
          {!showOTP ? (
            <>
              <TextInput
                labelText="Username"
                inputName="userName"
                required
                onChange={HandleInput}
                customLabelStyles={styles.space}
                customInputStyles={styles.fullWidth}
              />
              <label className={css(styles.label)}>
                <p className={css(styles.space)}>Password</p>
                <input
                  type="password"
                  name="password"
                  onChange={HandleInput}
                  className={css(styles.bar, styles.fullWidth)}
                />
              </label>
              <p
                style={{
                  fontSize: 13,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: 'blue',
                }}
                onClick={onClickForgotPassword}
              >
                Forgot Password
              </p>
            </>
          ) : (
            <>
              <TextInput
                labelText="OTP (Sent to registered email)"
                inputName="OTP"
                required
                onChange={(e) => setOTP(e.target.value)}
                value={OTP}
              />
              <p style={{ marginTop: 5, marginBottom: -25, fontSize: 13 }}>
                Expires in 1 minute...
              </p>
            </>
          )}
          <div className={css(styles.space)}>
            <Submit value="LOGIN" customStyles={styles.fullWidth} />
          </div>
        </form>
      </div>
    </div>
  )
}

export default LoginForm

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  bg: {
    background: '#FFD500',
    display: 'flex',
    alignItems: 'center',
  },
  form: {
    background: '#fff',
    margin: '1.5rem 0',
    marginRight: '1.5rem',
    padding: '1.5rem',
    width: '28rem',
  },
  logo: {
    margin: '0 1.5vw 0 1.5vw',
    width: '200px',
  },
  title: {
    fontSize: '1rem',
    color: 'rgba(86, 84, 85, 1)',
  },
  subTitle: {
    fontSize: '.8rem',
    color: 'rgba(180, 180, 180, 1)',
  },
  space: {
    marginTop: '2rem',
    display: 'block',
  },
  fullWidth: {
    width: '100%',
    boxSizing: 'border-box',
  },
  bar: {
    border: 0,
    outline: 0,
    background: '#FFD500',
    padding: '.5rem',
    borderRadius: '.5rem',
  },
  label: {
    fontWeight: 600,
    marginBottom: 20,
  },
})
