import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { userUserInfo } from "../../auth/hooks/useUserInfo";
import { BASE_URL } from "../../constants";
import { useModal, useStatusMessage } from "../../store";
import DeleteIcon from "../../svgs/DeleteIcon";
import Button from "../../uiElements/Button";
import ListHeader from "../../uiElements/ListHeader";
import ListItem from "../../uiElements/ListItem";
import { Description } from "../../uiElements/Description";
import { ResponseModal } from "../userProfiles/AddUsers";
import { css, StyleSheet } from "aphrodite";
export const Devices = () => {
  const user = userUserInfo();
  const [devices, setDevices] = useState([]);
  const { setComponent } = useModal();
  const [otp, setOtp] = useState("");
  const { setMessage } = useStatusMessage();
  const [showLogins, setShowLogins] = useState();
  const generateOtp = async () => {
    const otp = await axios.post(`${BASE_URL}/auth/user/device/otp`);
    console.log(otp.data, "new otp");
    setOtp(otp.data.otp);
    setComponent(<ResponseModal otp={otp.data.otp} />);
  };
  const recentLogins = useMemo(async () => {
    if (showLogins === null) return [];
    console.log(showLogins, devices[showLogins].deviceId);
    const deviceId = devices[showLogins].deviceId;
    try {
      const logins = await axios.get(
        `${BASE_URL}/auth/user/device-logins/${deviceId}`
      );
      console.log(logins.data);
      return logins.data;
    } catch (err) {
      console.log(err);
      return;
    }
  }, [showLogins]);
  const fetchDevices = () => {
    axios
      .get(`${BASE_URL}/auth/user/device`)
      .then(({ data }) => setDevices(data))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchDevices();
  }, []);
  const deleteDevice = async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}/auth/user/device/${id}`);
      setMessage("Successfully deleted device: " + id);
      fetchDevices();
      console.log(response);
    } catch (err) {
      console.log(err);
      setMessage("Delete Failed...");
    }
  };
  return (
    <div>
      {/* <p>{otp}</p> */}
      <ListHeader
        title="DEVICE LIST"
        btnContent={user.roles === "SuperAdmin" ? "ADD MODULE" : ""}
        description={"In this section, you will be able to authorize devices to be able to use the Reactor Internals VR Application, Click the button below to generate an OTP to register one new device"}
      />
      <div style={{ marginLeft: 40 }}>
        <Button customStyles={styles.button} clickHandler={generateOtp}>
          Genereate OTP
        </Button>
      </div>
      {devices.map((device, idx) => (
        <div
          // onMouseEnter={() => setShowLogins(idx)}
          // onMouseLeave={() => setShowLogins()}
        >
          <ListItem key={device._id} title={`Device Id: ${device.deviceId}`}>
            {/* <WrenchIcon wrenchStyle={css(styles.wrench)} /> */}
            {showLogins === idx && (
              <div>
                {recentLogins &&
                  recentLogins.map((login) => {
                    <div>{login.userId}</div>;
                  })}
              </div>
            )}
            <div>
              {/* <EditIcon /> */}
              {<DeleteIcon onClick={() => deleteDevice(device.deviceId)} />}
            </div>
          </ListItem>
        </div>
      ))}
    </div>
  );
};

const styles = StyleSheet.create({
  button: {
    margin: 15,
  },
});
