import BreadCrumb from '../../uiElements/BreadCrumb'
import Button from '../../uiElements/Button'
import { css, StyleSheet } from 'aphrodite'
import TertiaryList from '../../uiElements/TertiaryList'
import EditIcon from '../../svgs/EditIcon'
import { useData, useStatusMessage } from '../../store'
import { useEffect, useState } from 'react'
import { Description } from '../../uiElements/Description'
import axios from 'axios'
import { BASE_URL } from '../../constants'
import { useModuleAssignments } from '../../auth/hooks/moduleApiHooks'
import { useClientUsers } from '../../auth/hooks/useClientUsers'

const ClientInfo = ({}) => {
	const { activeClient, setActiveClient, setActiveClientSubPage } = useData()
	function navToMainClientPage() {
		setActiveClientSubPage('main')
	}
	const { setMessage } = useStatusMessage()
	// const [modules, setModules] = useState([])
	const [modules] = useModuleAssignments(activeClient._id)
	const [admins, refresh] = useClientUsers(activeClient._id, '?roles=admin')
	const onDeleteAdmin = async (idx) => {
		console.log(idx, admins[idx])
		try {
			await axios.delete(`${BASE_URL}/auth/user/${admins[idx]._id}`)
			setMessage('Admin Deleted')
			refresh()
		} catch (err) {
			console.log(err)
			setMessage('Delete Failed')
		}
	}
	return (
		<div className={css(styles.container)}>
			<div className={css(styles.flexBlock)}>
				<BreadCrumb
					items={[
						{
							name: 'CLIENTS',
							clickHandler: navToMainClientPage,
						},
						{ name: activeClient.clientName },
					]}
				/>
			</div>
			<p style={{ fontSize: 15, marginBottom: -20 }}>
				In this section, you can create Admins or Trainer/Trainee
				accounts for the selected client{' '}
				<b>{activeClient.clientName}</b>. Please note that Admins will
				be able to create Trainer and Trainee accounts as well.
			</p>
			<div className={css(styles.flexBlock, styles.listsWrapper)}>
				{/* <TertiaryList
					title="MODULES"
					list={modules.map((mod) => mod.name)}
					customStyles={styles.singleList}
					sort
					edit
					options
					editClickHandler={() =>
						setActiveClientSubPage('module access')
					}
				/> */}
				{/* <TertiaryList
					title="TICKETS"
					list={''}
					customStyles={styles.singleList}
					sort
				/> */}
				<TertiaryList
					title="ADMINS"
					list={admins.map(
						(admin) => `${admin.userName} - ${admin.email ?? ''}`
					)}
					customStyles={styles.singleList}
					hasDelete
					onDelete={onDeleteAdmin}
				/>
			</div>
			<Button
				customStyles={styles.marginTop}
				clickHandler={() => setActiveClientSubPage('add admins')}
			>
				ADD ADMIN
			</Button>
			<div className={css(styles.flexBlock, styles.licensePayment)}>
				{/* <section>
					<h3>
						LICENSE <EditIcon />
					</h3>
					<p>License Version, Status, Expiry</p>
				</section> */}
				{/* <section>
					<h3>PAYMENT ORDERS</h3>
					<p>Paid,due or receipt view</p>
				</section> */}
			</div>
		</div>
	)
}

export default ClientInfo

const styles = StyleSheet.create({
	container: {
		paddingLeft: '3rem',
		paddingRight: '2rem',
	},
	marginTop: {
		marginTop: '15px',
	},
	flexBlock: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		// marginTop:'-2px',
		// marginBottom:'10px'
	},
	listsWrapper: {
		justifyContent: 'flex-start',
		gap: '2rem',
	},
	singleList: {
		width: '100%',
	},
	licensePayment: {
		justifyContent: 'flex-start',
		gap: '5rem',
		marginTop: '2rem',
	},
})
