import { css, StyleSheet } from 'aphrodite'
import axios from 'axios'
import { useState } from 'react'
import { useClientInfo } from '../../auth/hooks/useUserInfo'
import { BASE_URL } from '../../constants'
import { testUsername } from '../../helpers/testUsername'
import { useData, useStatusMessage } from '../../store'
import BreadCrumb from '../../uiElements/BreadCrumb'
import Button from '../../uiElements/Button'
import Submit from '../../uiElements/Submit'
import TextInput from '../../uiElements/TextInput'

const AddAdmins = () => {
  const { activeClient, setActiveClientSubPage } = useData()
  console.log('reneder')
  const INITIAL_NEW_ADMIN_DATA = {
    userName: '',
    email: '',
  }

  const [adminDetails, setAdminDetails] = useState(INITIAL_NEW_ADMIN_DATA)

  const HandleInput = (input) => {
    setAdminDetails((prev) => {
      const newObj = { ...prev }
      newObj[input.target.name] = input.target.value
      return newObj
    })
    // console.log('adminDetails', adminDetails)
  }
  const { setMessage } = useStatusMessage()
  // const clientInfo = useClientInfo(clientI)
  const submitHandler = async (e) => {
    e.preventDefault()
    const { userName, email } = adminDetails
    // console.log('userName, email', userName, email)
    try {
      // const { clientID } = await getUserData()
      console.log(activeClient)
      const clientID = activeClient._id
      // console.log('clientID', clientID)
      const newAdmin = {
        clientID,
        userName,
        roles: 'admin',
        email,
      }
      const res = await axios.post(`${BASE_URL}/auth/user/register`, newAdmin)
      console.log('res', res)
      setMessage('Invite sent to ' + adminDetails.email)
      setActiveClientSubPage('info')
      // console.log('res', res)
    } catch (err) {
      console.log(err)
      setMessage('Create failed:' + err.response?.data?.message)
    }
  }

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.header)}>
        <BreadCrumb
          items={[
            {
              name: 'CLIENTS',
              clickHandler: () => setActiveClientSubPage('main'),
            },
            {
              name: activeClient.clientName,
              clickHandler: () => setActiveClientSubPage('info'),
            },
            { name: 'NEW ADMIN' },
          ]}
        />
      </div>

      <h3>CREATE NEW ADMIN</h3>
      <div className={css(styles.space)}></div>
      <form className={css(styles.form)} onSubmit={submitHandler}>
        {/* <input className={css(styles.bar)}/> */}

        <TextInput
          readOnly={false}
          labelText="username"
          customInputStyles={styles.fullWidth}
          value={adminDetails.userName}
          onChange={(e) =>
            setAdminDetails((state) => ({
              ...state,
              userName: e.target.value,
            }))
          }
          userTips
          pattern="(.){5,20}"
          inputName="userName"
          id="admin_name"
        />

        <label className={css(styles.label)}>
          <p>email</p>
        </label>
        <input
          onFocus={() => console.log('focus')}
          type="email"
          name="eamil"
          value={adminDetails.email}
          onChange={(e) =>
            setAdminDetails((state) => ({
              ...state,
              email: e.target.value,
            }))
          }
          className={css(styles.bar, styles.fullWidth)}
        />

        <div className={css(styles.space)}>
          <Submit
            value="Invite"
            customStyles={styles.fullWidth}
            disabled={!testUsername(adminDetails.userName)}
          />
        </div>
      </form>
    </div>
  )
}

export default AddAdmins

const styles = StyleSheet.create({
  container: {
    paddingLeft: '3rem',
    paddingRight: '2rem',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  form: {
    width: '20%',
    // marginTop: '500px'
  },
  label: {
    fontWeight: 600,
  },
  space: {
    marginTop: '1.5rem',
  },
  fullWidth: {
    width: '100%',
    boxSizing: 'border-box',
  },
  bar: {
    border: 0,
    outline: 0,
    background: 'rgba(217, 217, 217, 1)',
    padding: '.5rem',
    borderRadius: '.5rem',
  },
})
