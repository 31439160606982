import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../constants";

export const useModuleAssignments = (clientID) => {
  const [modules, setModules] = useState([]);

  const fetch = async () => {
    if(!clientID)return
    const modules = await axios.get(
      `${BASE_URL}/api/v1/moduleassignment?clientID=${clientID}`
    );
    let modulesResolved = [];
    for (module of modules.data) {
      const moduleId = module.moduleId;
      // console.log(moduleId);
      const response = await axios.get(
        `${BASE_URL}/api/v1/module?_id=${moduleId}`
      );
      // console.log(response.data);
      modulesResolved.push(response.data[0]);
    }
    setModules(modulesResolved);
  };
  useEffect(() => {
    fetch();
  }, [clientID]);

  return [modules, fetch];
};

export const useGetAllModules = () => {
  const [modules, setModules] = useState([]);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/module`)
      .then((res) => setModules(res.data))
      .catch((err) => console.log(err));
  }, []);
  return modules;
};
