import React from 'react'

export const Description = ({text, customStyles}) => {
  return (
    
    <div style={{
      marginTop: 10,
      width: '70%',
      fontSize: 14,
      marginBottom: 20
    }}>{text.split('\n').map((l) => {
      return <p style={{margin:1}}>{l}</p>
    })}</div>
  )
}

