import { css, StyleSheet } from 'aphrodite'
import axios from 'axios'
import { userUserInfo } from '../../auth/hooks/useUserInfo'
import { BASE_URL } from '../../constants'
import { useData, useStatusMessage } from '../../store'
import BreadCrumb from '../../uiElements/BreadCrumb'
import Button from '../../uiElements/Button'
import { DropDown } from '../../uiElements/DropDown'
import TextInput from '../../uiElements/TextInput'

const SingleUserProfile = () => {
  const { activeUserProfile, setActiveUserProfile } = useData()
  const { userName, deviceId, roles, _id } = activeUserProfile
  // console.log('userName', userName, 'deviceId', deviceId, 'roles', roles)
  const { setMessage } = useStatusMessage()

  function mainUsersPage() {
    setActiveUserProfile({})
  }

  const user = userUserInfo(_id)
  const updateUserRole = async (e) => {
    e.preventDefault()
    const userData = {
      userName,
      roles,
    }
    const userID = _id
    const res = await axios.put(
      `${BASE_URL}/auth/user/editUser/${userID}`,
      userData
    )
    // console.log('res', res, res.data)
    // console.log(userID, activeUserProfile)
  }
  // console.log('usssssser', user)

  const onResetPassword = async () => {
    // if (!userName) return setMessage('Please enter your username')
    setMessage(`Please check your registered email for ${userName}...`)
    try {
      const response = await axios.post(
        `${BASE_URL}/auth/user/forgot-password`,
        { userName: userName }
      )
      console.log('reset password res: ', response)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <BreadCrumb
        items={[
          { name: 'USERS', clickHandler: mainUsersPage },
          { name: userName },
        ]}
      />
      <form onSubmit={updateUserRole}>
        <TextInput
          labelText="Username"
          customInputStyles={styles.customWidth}
          value={userName}
          readOnly
        />
        {/* <TextInput
					labelText="Device ID"
					customInputStyles={styles.customWidth}
					value={deviceId}
					readOnly
				/> */}
        <TextInput
          labelText={'Email'}
          customInputStyles={styles.customWidth}
          value={user.email}
          readOnly
        />
        <DropDown
          labelText="USER ROLE"
          value={roles}
          onChange={(val) =>
            setActiveUserProfile({
              ...activeUserProfile,
              roles: val,
            })
          }
          customInputStyles={styles.customWidth}
          items={['trainee', 'trainer']}
        />
        <div className={css(styles.btnContainer)}>
          <button
            className={css(styles.customBtn)}
            onClick={() => onResetPassword()}
          >
            Send Reset Password Request
          </button>
        </div>
        <div className={css(styles.btnContainer)}>
          <Button customStyles={styles.button}>UPDATE INFO</Button>
        </div>
      </form>
    </>
  )
}

export default SingleUserProfile

const styles = StyleSheet.create({
  btnContainer: {
    marginTop: 20,
  },
  customWidth: {
    width: '20%',
    boxSizing: 'content-box',
  },
  customBtn: {
    background: 'none',
    outline: 'none',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: 'blue',
    border: 'none',
  },
})
