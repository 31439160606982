import axios from 'axios'

export const isTokenValid = (tokenName, timestampName) => {
  // console.log(
  // 	tokenName,
  // 	localStorage.getItem(tokenName),
  // 	Number(localStorage.getItem(timestampName))
  // )
  if (
    localStorage.getItem(tokenName) &&
    new Date().getTime() - Number(localStorage.getItem(timestampName)) <
      // 4.32 * 10 ** 7
      1.08 * 10 ** 7
  ) {
    axios.defaults.headers.common.authorization = `Bearer ${localStorage[tokenName]}`
    return true
  }
  return false
}
