import axios from "axios"
import { useEffect, useState } from "react"
import { BASE_URL } from "../../constants"

export const useGetModulesWithEvaluations = (clientID, userId, modules) => {
  const [evals, setEvals] = useState([])
  useEffect(() => {
    if(!userId)return
    const fetch = async () => {
      const evaluation = await axios.get(`${BASE_URL}/api/v1/module/moduleswithevaluations/${clientID}/${userId}`)
      evaluation.data.forEach(module => {
        if(!module.evaluations.length)return
        let score = module.evaluations.reduce((a,b)=> {
          console.log(b.score)
          return a+ (b.score==='1'?1:0)
        },0)
        module.score = `Score: ${score}/${module.evaluations.length}`
      })
      setEvals(evaluation.data.filter(evaluation=>evaluation.score))
      // setEvals(evaluation.data)
      
      console.log(evaluation)
    }
    fetch()

  }, [userId, modules])
  console.log(evals)
  return evals
}