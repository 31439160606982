import React from 'react'

function FormUserTips({ id }) {
  const elem = document.getElementById(id)
  // useEffect(() => {
  //   if (elem === document.activeElement.id) {
  //     console.log('use effect Element has focus!')
  //   } else {
  //     console.log(`useEffect Element is not focused.` + id)
  //   }
  // }, [elem])
  return (
    <>
      <div
        style={{
          display:
            elem != null && elem === document.activeElement ? 'block' : 'none',
        }}
      >
        <ul>
          <li>Character limit is 5-20</li>
          <li>Except underscore(_), special characters are not allowed</li>
        </ul>
      </div>
    </>
  )
}

export default FormUserTips
