import { css, StyleSheet } from 'aphrodite'
import React from 'react'

const Submit = ({ disabled, value, customStyles, onSubmit }) => {
  return (
    <input
      disabled={disabled && disabled}
      type="submit"
      value={value}
      className={css(styles.sumbitBtn, customStyles)}
      onClick={onSubmit}
    />
  )
}

export default Submit

const styles = StyleSheet.create({
  sumbitBtn: {
    border: 0,
    background: 'rgba(251, 206, 7, 1)',

    borderRadius: '.5rem',
    padding: '.5rem 1.5rem',
    fontWeight: 500,
    margin: 5,
    fontSize: '.7rem',
    ':hover': {
      cursor: 'pointer',
    },
  },
})
