import { StyleSheet, css } from 'aphrodite'
import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { Link, useSearchParams } from 'react-router-dom'
import { userUserInfo } from '../../auth/hooks/useUserInfo'
import { BASE_URL } from '../../constants'
import { testPassword } from '../../helpers/testPassword'
import { useStatusMessage } from '../../store'
import Header from '../../uiElements/Header'
import Submit from '../../uiElements/Submit'
import TextInput from '../../uiElements/TextInput'

export const ResetPassword = () => {
  const location = useLocation()
  const [form, setForm] = useState({
    password: '',
    newPassword: '',
    confirmPassword: '',
  })

  const [userName, clientName, userId, key, roles] = useMemo(() => {
    const params = new URLSearchParams(location.search)
    setForm((state) => ({ ...state, password: params.get('key') }))

    return [
      params.get('userName'),
      params.get('clientName'),
      params.get('userId'),
      params.get('key'),
      params.get('roles'),
    ]
  }, [location.search])

  const user = userUserInfo(userId)
  const [isPasswordSet, setIsPasswordSet] = useState(false)

  const { setMessage } = useStatusMessage()
  // useEffect(() => {
  //   const params = new URLSearchParams(location.search)
  //   // location.
  //   // axios.headers.def
  //   console.log('user role: ', userId)
  //   console.log('roles:', roles)
  // }, [roles])

  const handleSubmit = async () => {
    try {
      console.log(form)
      const response = await axios.post(
        `${BASE_URL}/auth/user/reset-password/${userId}`,
        form
      )
      setMessage('Password reset successfully: ' + response.data?.message)
      console.log('reset confirm data', response.data)
      setIsPasswordSet(true)
    } catch (err) {
      console.log(err)
      setMessage(
        'Something went wrong: ' + err.response.data.message || err.message
      )
    }
  }
  return (
    <div
      style={{
        alignContent: 'center',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        // backgroundColor:'red'
      }}
    >
      <div className={css(styles.header)}>
        <img
          src={'./shelllogo.png'}
          height="40px"
          style={{ marginRight: '2rem', marginLeft: '0px' }}
        />
      </div>
      {isPasswordSet ? (
        <>
          <h2 className={css(styles.passwordSetText)}>
            {roles === 'admin' || roles === 'superAdmin'
              ? `Congratulations!!! Password set successfully for 
						${userName} from ${clientName}`
              : `Login to the Shell Reactor Internals VR Experience using these credentials`}
          </h2>
          {(roles === 'admin' || roles === 'superAdmin') && (
            <Link to="/login" className={css(styles.login)}>
              login
            </Link>
          )}
        </>
      ) : (
        <>
          <h2 style={{ textAlign: 'center' }}>
            Set password for {userName} from {clientName}
          </h2>
          <div
            style={{
              width: '500px',
              position: 'absolute',
              left: '50vw',
              top: '50vh',
              transform: 'translate(-50%,-50%)',
            }}
          >
            {/* <TextInput
          labelText={'Old password'}
          value={form.password}
          onChange={(e) => setForm({...form, password: e.target.value})}
          password
        /> */}
            <div style={{ fontSize: 10, width: '500px' }}>
              <p>
                Please use a strong password containing lowercase and uppercase
                letters, special characters, numbers and minimum 8 characters.
              </p>
              <p>Allowed special characters are @._</p>
            </div>
            <TextInput
              password
              labelText={'New password'}
              onChange={(e) =>
                setForm((state) => ({
                  ...state,
                  newPassword: e.target.value,
                }))
              }
              value={form.newPassword}
            />
            <TextInput
              password
              labelText={'Confirm password'}
              onChange={(e) =>
                setForm((state) => ({
                  ...state,
                  confirmPassword: e.target.value,
                }))
              }
              value={form.confirmPassword}
            />
            <div style={{ marginTop: 8 }}></div>
            <Submit
              disabled={
                form.newPassword !== form.confirmPassword ||
                !testPassword(form.newPassword)
              }
              onSubmit={handleSubmit}
            />
            {/* <TextInput
          labelText={'Confirm new password'}
        /> */}
          </div>
        </>
      )}
    </div>
  )
}
const styles = StyleSheet.create({
  header: {
    borderBottom: '2px solid rgba(219, 225, 232, 1)',
    display: 'flex',
    padding: '1rem 2rem 0',
  },
  passwordSetText: {
    color: 'green',
  },
  login: {
    fontSize: '1.3rem',
  },
})
