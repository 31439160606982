import { StyleSheet, css } from 'aphrodite'
import { useEffect, useMemo, useState } from 'react'
import uuid from 'react-uuid'
import Filter from '../svgs/Filter'
import AdvancedMultiSelect from '../uiElements/AdvancedMultiSelect'
import BasicMultiSelect from '../uiElements/BasicMultiSelect'
import Button from '../uiElements/Button'
import { SecondaryListOnlyUI } from '../uiElements/SecondaryListOnlyUI'
import data from './reportsData'

const Reports = () => {
  const sortyByOptions = [
    'modules',
    'users'
  ]
  const [factories, setFactories] = useState([])
  const [modules, setModules] = useState([])
  const [sortBy, setSortBy] = useState('modules')
  const [isFactoriesOpen, setIsFactoriesOpen] = useState(false)
  const [isUsersOpen, setIsUsersOpen] = useState(false)
  const [showFactories, setShowFactories] = useState([])
  const [users, setUsers] = useState([])
  const [showUsers, setShowUsers] = useState([])
  const [usersSearchQuery, setUsersSearchQuery] = useState('')
  const filteredUsers = useMemo(
    () => users.map(
      userInfo => {
        const matchedUsers = userInfo.users.filter(
          user => {
            // console.log('user in filteredUsers', user)
            // const regex = new RegExp(usersSearchQuery, 'gi')
            // return regex.test(user)
          }
        )
        // console.log('matchedUsers', matchedUsers)
        return { ...userInfo, users: matchedUsers }
      }
    ), [users, usersSearchQuery])

  // console.log('filteredUsers', filteredUsers, searchedUsers)
  // console.table(filteredUsers)

  useEffect(() => {
    // console.log('data in useEffect', data)

    let allFactories = []
    data.forEach(user => !allFactories.includes(user.factoryName) && allFactories.push(user.factoryName))

    // console.log('allFactories', allFactories)
    setFactories([...allFactories])
    setShowFactories([...allFactories])
  }, [])

  useEffect(() => {
    // console.log('data in useEffect', data)

    let allModules = []
    data.forEach(user => {
      const factoryModules = []

      user.modules.forEach(module => factoryModules.push(module.moduleName))

      if (allModules.length) {
        const factoryExists = allModules.find(module => module.hasOwnProperty(user.factoryName))
        // console.log('factoryExists', factoryExists)
        if (!factoryExists) allModules.push({ [user.factoryName]: factoryModules })
      } else {
        // console.log('no properties present in allmodules', allModules)
        allModules.push({ [user.factoryName]: factoryModules })
      }
    })
    setModules([...allModules])
    // console.log('allModules', allModules)
  }, [])

  function factoryOptionCheckStatusHandler(e) {
    let customShowFactories = [...showFactories]
    if (e.target.checked) {
      customShowFactories.push(e.target.value)
    }
    else {
      customShowFactories = customShowFactories.filter(factory => factory !== e.target.value);
      // console.log('customShowFactories after remove', customShowFactories)
    }
    setShowFactories([...customShowFactories])
    // console.log('e', e.target.checked, e.target.value, e)
  }

  function checkIfFactoryPresent(factory) {
    // console.log('check if factory', factory)
    return showFactories.includes(factory)
  }


  useEffect(() => {
    const customUsers = []
    data.forEach(user => {
      // console.log('user in users', user)
      const factoryMatchedIndex = customUsers.findIndex(customUser => customUser.factoryName === user.factoryName)
      if (customUsers.length === 0 || factoryMatchedIndex === -1) {
        customUsers.push({ factoryName: user.factoryName, users: [user.userName] })
      } else if (factoryMatchedIndex >= 0) {
        // console.log('factory present in customUsers', user.factoryName, user.userName, factoryMatchedIndex, customUsers[factoryMatchedIndex])
        customUsers[factoryMatchedIndex].users.push(user.userName)
      }
    })
    setUsers([...customUsers])
    // console.log('data in users', data, '\n customUsers', customUsers)
  }, [])

  function userOptionCheckStatusHandler(e) {
    // console.log('e', e.target, e.target.value)
    let customShowUsers = [...showUsers]
    if (e.target.checked) {
      customShowUsers.push(e.target.value)
    }
    else {
      customShowUsers = customShowUsers.filter(factory => factory !== e.target.value);
      // console.log('customShowUsers after remove', customShowUsers)
    }
    setShowUsers([...customShowUsers])
  }

  function checkIfUserPresent(user) {
    // console.log('check if factory', factory)
    return showUsers.includes(user)
  }

  function resetOrSelectAllUsers(action) {
    if (action === "reset") {
      setShowUsers([])
    } else if (action === "selectAll") {
      const customShowUsers = []
      users.forEach(factory => {
        factory.users.forEach(user => customShowUsers.push(user))
      })
      setShowUsers([...customShowUsers])
      // console.log('customShowUsers', customShowUsers)
    }
  }

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.flexBlock)}>
        <h1>REPORT GENERATION</h1>
        <Button>
          EXPORT
        </Button>
      </div>
      <section className={css(styles.list)}>
        <div className={css(styles.flexBlock, styles.listHeader)}>
          <h2>Filter Report</h2>
        </div>
        <div className={css(styles.list, styles.listBody)}>
          <div className={css(styles.filters)}>
            <Filter />
            <select name="sort-by" className={css(styles.sortByFilter)} onChange={(e) => setSortBy(e.target.value)}>
              <option hidden>Sort by</option>
              <option value="modules">Modules</option>
              <option value="users">Users</option>
            </select>
            <BasicMultiSelect
              label={'Factories'}
              isDropDownOpen={isFactoriesOpen}
              setIsDropDownOpen={setIsFactoriesOpen}
              items={factories}
              changeHandler={factoryOptionCheckStatusHandler}
              checked={checkIfFactoryPresent}
            />
            <AdvancedMultiSelect
              label="Users"
              items={filteredUsers}
              isDropDownOpen={isUsersOpen}
              setIsDropDownOpen={setIsUsersOpen}
              checkBoxChangeHandler={userOptionCheckStatusHandler}
              checked={checkIfUserPresent}
              subLabelProp={'factoryName'}
              subItemsProp={'users'}
              selectAll={() => resetOrSelectAllUsers('selectAll')}
              reset={() => resetOrSelectAllUsers('reset')}
              searchChangeHandler={(e) => setUsersSearchQuery(e.target.value)}
            />
          </div>
          <div className='factories'>
            {
              showFactories.map(factory =>
                <table key={uuid()} className={css(styles.table)}>
                  <thead>
                    <tr>
                      <th className={css(styles.tableCell, styles.tableCellBorder, styles.factory)}>{factory}</th>
                      {sortBy === 'modules' ?
                        modules.map(factoryModules => {
                          if (factoryModules.hasOwnProperty(factory)) {
                            return factoryModules[factory].map((module) => (
                              <th key={uuid()} className={css(styles.tableCell, styles.tableCellBorder)}> {module} </th>
                            ))
                          }
                        })
                        :
                        data.map(user => user.factoryName === factory && (
                          showUsers.length ?
                            (showUsers.includes(user.userName) &&
                              (<th key={uuid()} className={css(styles.tableCell, styles.tableCellBorder)}>
                                {user.userName}
                              </th>)
                            ) :
                            (<th key={uuid()} className={css(styles.tableCell, styles.tableCellBorder)}>
                              {user.userName}
                            </th>)
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      sortBy === 'modules' ?
                        data.map(user => user.factoryName === factory && (
                          showUsers.length ?
                            (showUsers.includes(user.userName) &&
                              (<tr key={uuid()} >
                                <td className={css(styles.tableCell, styles.tableCellBorder)}> {user.userName} </td>
                                {
                                  user.modules.map(module =>
                                    <td key={uuid()} className={css(styles.tableCell, styles.tableCellBorder)}>
                                      {module.result}
                                    </td>
                                  )
                                }
                              </tr>)
                            )
                            :
                            (<tr key={uuid()} >
                              <td className={css(styles.tableCell, styles.tableCellBorder)}> {user.userName} </td>
                              {
                                user.modules.map(module =>
                                  <td key={uuid()} className={css(styles.tableCell, styles.tableCellBorder)}>
                                    {module.result}
                                  </td>
                                )
                              }
                            </tr>)
                        ))
                        :
                        modules.map(factoryModules => {
                          if (factoryModules.hasOwnProperty(factory)) {
                            return factoryModules[factory].map((module, moduleIndex) =>
                              <tr key={uuid()}>
                                <td className={css(styles.tableCell, styles.tableCellBorder)}> {module} </td>
                                {
                                  data.map((user, index) => {
                                    if (user.factoryName === factory) {
                                      if (showUsers.length) {
                                        if (showUsers.includes(user.userName))
                                          return (
                                            <td key={uuid()} className={css(styles.tableCell, styles.centerAlign, styles.tableCellBorder)}>
                                              {user.modules[moduleIndex].result}
                                            </td>
                                          )
                                      } else {
                                        return (
                                          <td key={uuid()} className={css(styles.tableCell, styles.centerAlign, styles.tableCellBorder)}>
                                            {user.modules[moduleIndex].result}
                                          </td>
                                        )
                                      }
                                    }
                                  })
                                }
                              </tr>)
                          }
                        })
                    }
                  </tbody>
                </table>
              )
            }
          </div>
        </div>

      </section>
    </div>
  )
}

export default Reports

const primaryColor = localStorage.getItem('primaryColor')
// console.log('primaryColor in reports', primaryColor)

const styles = StyleSheet.create({
  container: {
    paddingLeft: '3rem',
    paddingRight: '2rem',
  },
  list: {
    borderRadius: 10,
    background: primaryColor,
    padding: 0,
    border: `3px solid ${primaryColor}`,
  },
  listHeader: {
    padding: '1rem 1rem 0px',
  },
  listBody: {
    background: '#fff',
    paddingLeft: '1rem',
    height: '55vh',
    overflow: 'auto',
    '::-webkit-scrollbar': {
      width: '.5rem',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#ededed',
      borderRadius: '2.4vh',
    },
  },
  flexBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  table: {
    background: '#ededed',
    margin: '1rem',
  },
  tableCell: {
    padding: '.5rem .7rem',
    verticalAlign: 'middle',
  },
  tableCellBorder: {
    ':nth-child(n):not(:last-child)': {
      borderRight: '1px solid #D9D9D9',
    }
  },
  centerAlign: {
    textAlign: 'center',
  },
  factory: {
    fontSize: '1.3rem',
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    marginTop: '1rem',
  },
  sortByFilter: {
    width: '15%',
    height: '1.8rem',
    borderRadius: '0.3rem',
    border: '1px solid black',
  }
})
